
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "FallingSky";
  src: url(./Theme/Fonts/FallingSky/FallingSky.woff);
}

@font-face {
  font-family: "FallingSkyLight";
  src: url(./Theme/Fonts/FallingSky/FallingSkyMed.woff);
}

@font-face {
  font-family: "FallingSky Bold";
  src: url(./Theme/Fonts/FallingSky/FallingSkyBd.woff);
}

@font-face {
  font-family: "HandSean";
  src: url(./Theme/Fonts/HandOfSean/Hand_Of_Sean_Demo.ttf);
}

@font-face {
  font-family: "FiraSans";
  src: url(./Theme/Fonts/FiraSans/FiraSans-Regular.ttf);
}

@font-face {
  font-family: "FiraSans-Medium";
  src: url(./Theme/Fonts/FiraSans/FiraSans-Medium.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* RESET */
html, body, div, h1, h2, h3, h4, h5, h6, p,
address, cite, code,
del, dfn, em, img, small, strike, sub, sup,
center, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, figcaption, footer, header, hgroup, 
menu, nav, section, button {
	margin: 0;
	padding: 0;
	border: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

:root {
  --color_green: #008742;
  --color_mustard:  #e89f02;
  --color_brown: #b33f10;
  --color_white: #fdfeff;
  --color_blue: #0076b3;
  --color_black: #050505;
  --color_lightGray:  #656565;
}

h2 {
  font-family: HandSean;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: #0000ee;
}

/* a:visited {
  color: #0000ee;
} */

::-webkit-scrollbar {
  width: 5px; 
}

::-webkit-scrollbar-thumb {
  background-color:  #e89f02; 
  border-radius: 8px; 
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
